import React, { useState, useEffect } from 'react';
import { 
  Box, Flex, Button, Link, Image, Menu, MenuButton, MenuList, MenuOptionGroup, MenuItemOption, 
  IconButton, Skeleton, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure,
  Divider
} from '@chakra-ui/react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GrLanguage } from 'react-icons/gr';
import { HamburgerIcon } from '@chakra-ui/icons';

const Header = () => {
  const location = useLocation();
  const { t, i18n, ready } = useTranslation('homepage');
  const [showContent, setShowContent] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (ready) {
      setShowContent(true);
    }
  }, [ready]);

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  const MenuItem = ({ to, children, onClick }) => {
    const isSelected = (to === '/' && location.pathname === '/') || (to !== '/' && location.pathname === to);
    return (
      <Button
        as={RouterLink}
        to={to}
        target="_blank"
        rel="noopener noreferrer"
        mr={4}
        bg="none"
        width="60px"
        borderRadius={16}
        fontWeight={isSelected ? "bold" : "regular"}
        textColor={isSelected ? "#364954" : "#737D82"}
        _hover={{ 
            transform: 'scale(1.02)',
            fontWeight: 'bold'
        }}
        transition="all 0.3s ease"
        onClick={onClick}  /* For closing drawer on mobile */
      >
        {children}
      </Button>
    );
  };

  const renderContent = (isMobile = false) => (
    <Flex 
      alignItems={isMobile ? 'flex-start' : "center"} 
      gap="1" 
      flexDirection={isMobile ? 'column' : 'row'}
    >
      <MenuItem to="/" onClick={onClose}>{t('home')}</MenuItem>
      <MenuItem to="/pricing" onClick={onClose}>{t('pricing')}</MenuItem>
      <MenuItem to="/about" onClick={onClose}>{t('about')}</MenuItem>
      <Button 
        ml={isMobile ? 0 : 2}
        mt={isMobile ? 4 : 0}
        as={RouterLink} 
        to="/gettingstarted" 
        borderRadius="full"
        color="#ffffff" 
        bg="#364954"
        _hover={{
          color:"#ffffff",
          bg:"#82B2CA"
        }}
        onClick={onClose}  /* For closing drawer on mobile */
      >
        {t('getStarted')}
      </Button>
  
      {/* Language Switcher */}
      {isMobile ? (
        <>
        <Divider mt={5} />
        <Flex direction="row" mt={5}>
          <Button 
            variant="link" 
            color="#364954" 
            onClick={() => handleLanguageChange('zh')}
          >
            中文
          </Button>
          <Button 
            ml="10"
            variant="link" 
            color="#364954" 
            onClick={() => handleLanguageChange('en')}
          >
            English
          </Button>
        </Flex>
        </>
      ) : (
        <Box ml={4} mt={isMobile ? 4 : 0}>
          <Menu>
            <MenuButton 
              as={IconButton} 
              icon={<GrLanguage />} 
              colorScheme='gray' 
              borderWidth={0} borderRadius={16} 
              variant="outline" 
              aria-label="Language Switcher"
            />
            <MenuList>
              <MenuOptionGroup type='radio' defaultValue={i18n.language}>
                <MenuItemOption value='zh' onClick={() => handleLanguageChange('zh')}>中文</MenuItemOption>
                <MenuItemOption value='en' onClick={() => handleLanguageChange('en')}>English</MenuItemOption>
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        </Box>
      )}
    </Flex>
  );
  

  const renderPlaceholder = () => (
    <Flex alignItems="center" gap="1">
      <Skeleton height="40px" width="80px" mr={4} />
      <Skeleton height="40px" width="80px" mr={4} />
      <Skeleton height="40px" width="80px" mr={4} />
      <Skeleton height="40px" width="120px" ml={4} />
      <Skeleton height="40px" width="40px" ml={4} borderRadius="50%" />
    </Flex>
  );

  return (
    <Box 
      as="header" 
      position="fixed" 
      top={0} left={0} right={0} 
      bg="rgba(255, 255, 255, 0.3)"
      zIndex={10}
      height="80px"
      backdropFilter="blur(15px)"
      boxShadow="sm"
    >
      <Flex 
        maxW="1200px" mx="auto" height="100%"
        px={4} py={2} 
        align="center" 
        justifyContent="space-between"
      >
        <Link as={RouterLink} to="/">
          <Image src="/myCareerHelp.png" alt="MyCareerHelp Logo" h="50px" />
        </Link>

        {/* Desktop Menu */}
        <Box display={{ base: 'none', md: 'block' }}>
          {showContent ? renderContent() : renderPlaceholder()}
        </Box>

        {/* Mobile Menu - Hamburger Icon */}
        <Box display={{ base: 'block', md: 'none' }} borderRadius={16}>
          <IconButton 
            icon={<HamburgerIcon />} 
            variant="outline" 
            onClick={onOpen} 
            aria-label="Open Menu"
            borderRadius={16}
          />
          <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
          >
            <DrawerOverlay 
              backdropFilter="blur(10px)"
            />
            <DrawerContent
              borderLeftRadius={16}
            >
              <DrawerCloseButton />
              <DrawerHeader>
                <Image src="/myCareerHelp.png" alt="MyCareerHelp Logo" h="40px" />
              </DrawerHeader>

              <DrawerBody>
                {showContent ? renderContent(true) : renderPlaceholder()}
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Box>
      </Flex>
    </Box>
  );
};

export default Header;
