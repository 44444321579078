import React, { useState } from 'react';
import axiosInstance from '../utils/axiosConfig';
import { useNavigate } from 'react-router-dom';
import {
  Box, Button, Container,
  FormControl, FormLabel, Input, VStack, Text,
  useToast,
  Menu, MenuButton, IconButton, MenuList, MenuOptionGroup, MenuItemOption,
  Spinner, useBreakpointValue
} from '@chakra-ui/react';
import { GrLanguage } from "react-icons/gr";
import { ArrowLeft } from 'lucide-react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Turnstile } from "@marsidev/react-turnstile";

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const [, setLanguage] = useState(i18next.language || 'zh');
  const { t } = useTranslation();
  const [turnstileResponse, setTurnstileResponse] = useState(null);

  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleLanguageChange = (value) => {
    setLanguage(value);
    i18next.changeLanguage(value);
  };

  const handleTurnstileVerify = (token) => {
    setTurnstileResponse(token);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) return; // Prevent multiple submissions

    if (!turnstileResponse) {
      toast({
        title: "Error",
        description: "Please complete the Turnstile challenge before submitting.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      return;
    }

    setIsLoading(true);
    try {
      const response = await axiosInstance.post('/api/v1/accounts/forgot-password/', { 
        email,
        cf_turnstile_response: turnstileResponse
      });
      toast({
        title: t('forgotpassword.success'),
        description: response.data.message,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      navigate('/sign-in');
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box 
      bg="#f5f7fa"
      minHeight="100vh" 
      py={8}>
      <Container maxW={isMobile ? "100%" : "500px"} px={isMobile ? 4 : 0}>
        <Box 
            borderWidth={1} 
            borderRadius="16px" 
            p={isMobile ? 6 : 8} 
            position="relative"
            bg="rgba(255, 255, 255, 0.7)"
            backdropFilter="blur(10px)"
            boxShadow="lg"
          >
          <Box position="absolute" top={4} left={8}>
            <Button
              variant="outline"
              onClick={() => navigate('/sign-in')}
              bg="#ffffff"
              size={isMobile ? "sm" : "md"}
            >
            <ArrowLeft 
              color="#364954"
              size={isMobile ? "16px" : "18px"}
            />
            </Button>
          </Box>

          <Box position="absolute" top={4} right={8}>
            <Menu>
              <MenuButton as={IconButton} icon={<GrLanguage />} colorScheme="gray" variant="outline" aria-label="Language Switcher" size={isMobile ? "sm" : "md"} />
              <MenuList>
                <MenuOptionGroup type='radio'>
                  <MenuItemOption value='zh' onClick={() => handleLanguageChange('zh')}>中文</MenuItemOption>
                  <MenuItemOption value='en' onClick={() => handleLanguageChange('en')}>English</MenuItemOption>
                </MenuOptionGroup>
              </MenuList>
            </Menu>
          </Box>
          <VStack mt={5} spacing={4} align="stretch">
            <Text fontSize={isMobile ? "xl" : "2xl"} fontWeight="bold" textAlign="center">{t('forgotpassword.title')}</Text>
            <form onSubmit={handleSubmit}>
              <VStack spacing={4}>
                <FormControl isRequired>
                  <FormLabel>{t('forgotpassword.emailfield')}</FormLabel>
                  <Input 
                    type="email" 
                    value={email} 
                    isDisabled={isLoading}
                    onChange={(e) => setEmail(e.target.value)} 
                    placeholder={t('forgotpassword.email_placeholder')}
                  />
                </FormControl>
                <Turnstile
                  siteKey="0x4AAAAAAAgeO6JOT4_Euowv"
                  onSuccess={handleTurnstileVerify}
                  onError={(error) => {
                    console.error('Turnstile error:', error);
                    setTurnstileResponse(null);
                  }}
                  onExpire={() => {
                    console.log("Turnstile expired"); 
                    setTurnstileResponse(null);
                  }}
                  options={{
                    theme: 'light',
                    language: 'auto',
                    explicitRender: true,
                  }}
                />

                <Button 
                  width="full" 
                  type="submit"
                  isLoading={isLoading}
                  loadingText={t('forgotpassword.loading')}
                  isDisabled={isLoading || !turnstileResponse || !email}
                  color="#ffffff" 
                  backgroundColor="#364954"
                  borderRadius="16px"
                  _hover={{
                    color:"#ffffff",
                    backgroundColor:"#82B2CA"
                  }}
                >
                  {isLoading ? <Spinner size="sm" /> : t('forgotpassword.button')}
                </Button>
              </VStack>
            </form>
          </VStack>
        </Box>
      </Container>
    </Box>
  );
};

export default ForgotPassword;
