import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig'; // Make sure to import the axiosInstance
import {
  Box, Button, Flex,
  Menu, MenuButton, MenuOptionGroup, MenuList,
  FormControl, FormLabel, Input, InputGroup, InputRightElement, VStack, Text, Link, Image,
  useToast,
  IconButton,
  MenuItemOption, Skeleton, useBreakpointValue
} from '@chakra-ui/react';
import { GrLanguage } from "react-icons/gr";
import { ArrowLeft } from 'lucide-react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Turnstile } from "@marsidev/react-turnstile";

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const [, setLanguage] = useState(i18next.language || 'zh');
  const { t, i18n, ready } = useTranslation();
  const [turnstileResponse, setTurnstileResponse] = useState(null);
  const [showContent, setShowContent] = useState(false);

  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    const isSignedIn = localStorage.getItem('isLoggedIn') === 'true';
    if (isSignedIn) {
      navigate('/gettingstarted');
    } else {
    if (ready) {
      setShowContent(true);
    }
  }
}, [ready, navigate]);

  const handleLanguageChange = (value) => {
    setLanguage(value);
    i18next.changeLanguage(value);
  };

  const handleTurnstileVerify = (token) => {
    setTurnstileResponse(token);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return; // Prevent multiple submissions

    if (!turnstileResponse) {
      toast({
        title: "Error",
        description: "Please complete the Turnstile challenge before submitting.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await axiosInstance.post('/api/v1/accounts/sign-in/', {
        email,
        password,
        cf_turnstile_response: turnstileResponse,
      });

      localStorage.setItem('isLoggedIn', 'true');
      setTurnstileResponse(null);

      toast({
        title: t('signin.success'),
        description: response.data.message || t('signin.success_message'),
        status: "success",
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });

      navigate('/gettingstarted');

    } catch (error) {
      console.error(error.response?.data);
      const errorData = error.response?.data;

      const errorMessages = {
        "Email not verified": t('signin.failmsg.emailnotverify'),
        "Account doesn't exist": t('signin.failmsg.invalidemail'),
        "Invalid credentials": t('signin.failmsg.invalidcredentials'),
        "Invalid Turnstile response": t('signup.turnstile-invalid-desc'),
      };

      const defaultError = "An unexpected error occurred. Please try again.";

      const toastMessage = errorMessages[errorData?.message] || defaultError;

      toast({
        title: t('signin.fail'),
        description: toastMessage,
        status: "warning",
        duration: 8000,
        isClosable: true,
        position: 'top-right',
      });

    } finally {
      setIsSubmitting(false);
    }
  };

  const renderContent = () => (
    <VStack mt={5} spacing={4} align="stretch" width="100%">
      <Text fontSize={isMobile ? "24px" : "30px"} fontWeight="bold" textAlign="center" color="#364954">
        {t('signin.title')}
      </Text>
      <Text fontSize="sm" color="gray.500" textAlign="center">
        {t('signin.donthaveaccount')} <Link color="blue.500" href="/sign-up">{t('signin.signupbutton')}</Link>
      </Text>
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <VStack spacing={4} width="100%">
          <FormControl isRequired>
            <FormLabel>{t('signin.emailfield')}</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t('signin.email_placeholder')}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>{t('signin.passwordfield')}</FormLabel>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={t('signin.password_placeholder')}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? t('signin.password_hidebutton') : t('signin.password_showbutton')}
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <Link fontSize="sm" color="#364954" href="/forgot-password" alignSelf="flex-start">
            {t('signin.password_forgot')}
          </Link>
          <Turnstile
            siteKey="0x4AAAAAAAgeO6JOT4_Euowv"
            onSuccess={handleTurnstileVerify}
            onError={(error) => {
              console.error('Turnstile error:', error);
              setTurnstileResponse(null);
            }}
            onExpire={() => {
              console.log("Turnstile expired"); 
              setTurnstileResponse(null);
            }}
            options={{
              theme: 'light',
              language: 'auto',
              explicitRender: true,
            }}
          />
          <Button 
            width="full" 
            type="submit"
            color="#ffffff" 
            backgroundColor="#364954"
            borderRadius="16px"
            isLoading={isSubmitting}
            loadingText="Loading"
            isDisabled={isSubmitting || !turnstileResponse || !email || !password}
            _hover={{
              color:"#ffffff",
              backgroundColor:"#82B2CA"
            }}
          >
            {t('signin.signinbutton')}
          </Button>
          <Box
              align="center"
              mt={isMobile ? 1 : 3} 
              fontSize="12px"
              color="#737D82"
            >
              <Text>{t('signin.helpemailtext')}</Text>
          </Box>
        </VStack>
      </form>
    </VStack>
  );

  const renderPlaceholder = () => (
    <VStack mt={5} spacing={4} align="stretch" width="100%">
      <Skeleton height="36px" width="80%" alignSelf="center" />
      <Skeleton height="20px" width="60%" alignSelf="center" />
      <VStack spacing={4} width="100%">
        <Skeleton height="40px" width="100%" />
        <Skeleton height="40px" width="100%" />
        <Skeleton height="20px" width="40%" alignSelf="flex-start" />
        <Skeleton height="78px" width="100%" />
        <Skeleton height="40px" width="100%" />
      </VStack>
    </VStack>
  );

  return (
    <Flex direction="column" minHeight="100vh" height="100%">
      <Flex direction="column" flex="1" overflow="hidden">
        <Box
          flex="1"
          height="100%"
          bg="#f5f7fa"
          overflow="auto"
          p={isMobile ? 4 : 5}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <VStack width={isMobile ? "100%" : "500px"}>
            <Box 
              width="100%"
              bg="#ffffff"
              borderRadius="16px"
              p={isMobile ? 6 : 8}
              position="relative"
              boxShadow="lg"
            >
              <Box position="absolute" top={4} left={8}>
                <Button
                  variant="outline"
                  onClick={() => navigate('/')}
                  bg="#ffffff"
                  size={isMobile ? "sm" : "md"}
                >
                  <ArrowLeft 
                    color="#364954"
                    size={isMobile ? "16px" : "18px"}
                  />
                </Button>
              </Box>
              <Box position="absolute" top={4} right={8}>
                <Menu>
                  <MenuButton as={IconButton} icon={<GrLanguage />} colorScheme="gray" variant="outline" aria-label="Language Switcher" size={isMobile ? "sm" : "md"} />
                  <MenuList>
                    <MenuOptionGroup type='radio' defaultValue={i18n.language}>
                      <MenuItemOption value='zh' onClick={() => handleLanguageChange('zh')}>中文</MenuItemOption>
                      <MenuItemOption value='en' onClick={() => handleLanguageChange('en')}>English</MenuItemOption>
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              </Box>
              {showContent ? renderContent() : renderPlaceholder()}
            </Box>
            <Box align="center" mt={isMobile ? 8 : 10} > 
              <Image src="/myCareerHelp.png" alt={t('layout.site_logo')} mb={-1} mx="auto" width={isMobile ? "150px" : "200px"} />
              <Text fontSize="14px" color="#737D82">Empowering Careers, One Step at a Time.</Text>
            </Box>
          </VStack>
        </Box>
      </Flex>
    </Flex>
  );
};

export default SignIn;
