import React, { useState, useEffect, useContext } from 'react';
import axiosInstance from '../utils/axiosConfig';
import {
  Box,
  Heading,
  Text,
  VStack,
  Input,
  Button,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Spinner,
  Skeleton,
  Flex,
  Avatar,
  HStack, Fade
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserContext } from './UserContext';

const UpdateDisplayNameModal = ({ isOpen, onClose, onUpdateDisplayName }) => {
  const [newDisplayName, setNewDisplayName] = useState('');
  const { t, } = useTranslation();

  const handleSubmit = () => {
    onUpdateDisplayName(newDisplayName);
    setNewDisplayName('');
    onClose();
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose}
    >
      <ModalOverlay backdropFilter="blur(10px)"/>
      <ModalContent
        borderRadius="16px"
        boxShadow="lg"
        background="rgba(255, 255, 255, 0.9)"
        backdropFilter="blur(10px)"
        transition="all 0.1s ease"
        _hover={{ boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)' }}
      >
        <ModalHeader>{t('gettingstarted.updatedisplayname.title')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            placeholder={t('gettingstarted.updatedisplayname.modalplaceholder')}
            value={newDisplayName}
            onChange={(e) => setNewDisplayName(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button 
            onClick={handleSubmit}
            size="sm"
            borderRadius="12px"
            variant='solid'
            backgroundColor="#364954"
            color="#ffffff"
            _hover={{
                color: "#ffffff",
                backgroundColor: "#82B2CA"
            }}
          >
            {t('gettingstarted.updatedisplayname.save')}
          </Button>
          <Button 
            variant="ghost" 
            onClick={onClose}
          >
            {t('gettingstarted.updatedisplayname.cancel')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const Settings = () => {
  const { fetchUserProfile: contextFetchUserProfile } = useContext(UserContext);
  const [profile, setProfile] = useState(null);
  const { t, ready } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdatingName, setIsUpdatingName] = useState(false);
  const [isDeletingAccount, setIsDeletingAccount] = useState(false);
  const [password, setPassword] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();

  const fetchUserProfile = async () => {
    try {
      const response = await axiosInstance.get('/api/v1/accounts/profile/');
      setProfile(response.data);
    } catch (error) {
      toast({
        title: t('settings.fetch_profile_fail'),
        description: error.response?.data?.message || 'An error occurred',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const handleUpdateDisplayName = async (newDisplayName) => {
    if (isUpdatingName) return;

    setIsUpdatingName(true);
    try {
      const response = await axiosInstance.put('/api/v1/accounts/update-display-name/', { display_name: newDisplayName });
      toast({
        title: t('settings.updatename_success'),
        description: t('settings.updatename_successdes'),
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      await contextFetchUserProfile(); // Update the user context
      await fetchUserProfile(); // Update the local profile state
    } catch (error) {
      toast({
        title: t('settings.updatename_fail'),
        description: error.response?.data?.message || 'An error occurred',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsUpdatingName(false);
    }
  };

  const handleDeleteAccount = async () => {
    if (isDeletingAccount) return;

    setIsDeletingAccount(true);
    try {
      const response = await axiosInstance.post('/api/v1/accounts/delete-account/', {
        password: password,
        refresh_token: localStorage.getItem('refresh_token')
      });

      toast({
        title: t('settings.deleteaccount_success'),
        description: response.data.message,
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });

      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      navigate('/');
    } catch (error) {
      toast({
        title: t('settings.deleteaccount_fail'),
        description: error.response?.data?.error || 'An error occurred',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsDeletingAccount(false);
      setPassword('');
    }
  };


  const SkeletonLoading = () => {
    return (
      <VStack spacing={4} align="stretch">
        <Box borderRadius="16px" p={5} bg="white">
          <Skeleton height="100px" mb={4} />
          <Skeleton height="20px" width="60%" mb={2} />
          <Skeleton height="20px" width="40%" />
        </Box>
        <Box borderRadius="16px" p={5} bg="white">
          <Skeleton height="24px" width="40%" mb={4} />
          <Skeleton height="16px" width="80%" mb={2} />
          <Skeleton height="40px" width="200px" />
        </Box>
      </VStack>
    );
  };

  return (
    <Flex direction="column" minHeight="100%" height="100%">
      <Box height="30px" flexShrink={0} />
      <Flex direction="column" flex="1" overflow="hidden">
        <Heading as="h1" fontSize="2xl" mb={4}>{t('settings.title')}</Heading>

        <Box
          flex="1"
          bg="#f5f7fa"
          borderTopLeftRadius="16px"
          borderTopRightRadius="16px"
          overflow="auto"
          p={5}
        >
          <Box flex="1">
            {isLoading ? (
              <SkeletonLoading />
            ) : (
              <Fade in={!isLoading}>
                <VStack align="stretch" spacing={4} height="100%">
                  <Box
                    p={5}
                    borderRadius={16}
                    background="rgba(255, 255, 255, 1)"
                    transition="all 0.3s ease"
                    _hover={{
                      boxShadow: '0 1px 30px rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    <Flex direction="column">
                      <Box>
                        {profile && (
                          <HStack>
                            <Avatar src={profile.profile_pic} size="xl" />
                            <VStack alignItems="left" ml={6}>
                              <Flex gap="2">
                                <Text fontSize="16px" fontWeight="bold">{t('settings.email_title')}: </Text>
                                <Text fontSize="16px" fontWeight="bold">{profile.email}</Text>
                              </Flex>
                              <Flex direction="row" gap="2">
                                <Text fontSize="16px">{t('settings.displayname_title')}: </Text>
                                <Text fontSize="16px">{profile.display_name}</Text>
                                <Button
                                  ml="8"
                                  size="sm"
                                  variant="link"
                                  textColor="#364954"
                                  onClick={onModalOpen}
                                  isLoading={isUpdatingName}
                                  spinner={<Spinner size="sm" color="white" />}
                                >
                                  {t('settings.updatedisplayname_button')}
                                </Button>
                              </Flex>
                            </VStack>
                          </HStack>
                        )}
                      </Box>
                    </Flex>
                  </Box>

                  <Box
                    p={5}
                    borderRadius={16}
                    background="rgba(255, 255, 255, 1)"
                    transition="all 0.3s ease"
                    _hover={{
                      boxShadow: '0 1px 30px rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    <Flex direction="column">
                      <Text fontSize="16px" fontWeight="bold" mb={4}>
                        {t('settings.deleteaccount_title')}
                      </Text>
                      <Text mb={3}>{t('settings.deleteaccount_des')}</Text>
                      <Button
                        size="sm"
                        width="200px"
                        borderRadius="12px"
                        colorScheme="red"
                        onClick={onOpen}
                        isDisabled={isLoading}
                      >
                        {t('settings.deleteaccount_button')}
                      </Button>
                    </Flex>
                  </Box>
                </VStack>
              </Fade>
            )}
          </Box>
        </Box>
      </Flex>

          <Modal isOpen={isOpen} onClose={onClose} size={'lg'}>
            <ModalOverlay  backdropFilter="blur(10px)" />
            <ModalContent
              borderRadius="16px"
              boxShadow="lg"
              background="rgba(255, 255, 255, 0.9)"
              backdropFilter="blur(10px)"
              transition="all 0.1s ease"
              _hover={{ boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)' }}
            >
              <ModalHeader>{t('settings.deleteaccount_modal_title')}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text as='b'>{t('settings.deleteaccount_modal_des1')}</Text>
                <Text mb={6}>{t('settings.deleteaccount_modal_des2')}</Text>
                <Text mb={4}>{t('settings.deleteaccount_modal_des3')}:</Text>
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password" />
              </ModalBody>
              <ModalFooter>
                <Button 
                  borderRadius="16px"
                  size="md"
                  colorScheme="red" 
                  mr={3} 
                  onClick={handleDeleteAccount} 
                  isLoading={isDeletingAccount}
                  isDisabled={!password}
                >
                  {t('settings.deleteaccount_confirmbutton')}
                </Button>
                <Button 
                  borderRadius="16px"
                  size="md"
                  variant="ghost" 
                  onClick={onClose}
                >
                  {t('settings.cancel')}
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <UpdateDisplayNameModal
            isOpen={isModalOpen}
            onClose={onModalClose}
            onUpdateDisplayName={handleUpdateDisplayName}
          />
    </Flex>
  );
};

export default Settings;