import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosConfig';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box, Button, Flex,
  Menu, MenuButton, MenuOptionGroup, MenuList,
  FormControl, FormLabel, Input, InputGroup, InputRightElement, VStack, Text, Link, Image,
  useToast,
  IconButton,
  MenuItemOption,
  Spinner, Skeleton, useBreakpointValue
} from '@chakra-ui/react';
import { GrLanguage } from "react-icons/gr";
import { ArrowLeft } from 'lucide-react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Turnstile } from "@marsidev/react-turnstile";

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [referralId, setReferralId] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const [, setLanguage] = useState(i18next.language || 'zh');
  const { t, i18n, ready } = useTranslation();
  const { referralCode } = useParams();
  const [turnstileResponse, setTurnstileResponse] = useState(null);
  const [showContent, setShowContent] = useState(false);

  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    if (referralCode) {
      setReferralId(referralCode);
    }
  }, [referralCode]);

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    if (isLoggedIn) {
      navigate('/gettingstarted');
    } else {
      if (ready) {
        setShowContent(true);
      }
    }
  }, [ready, navigate]);

  const handleLanguageChange = (value) => {
    setLanguage(value);
    i18next.changeLanguage(value);
  };

  const handleTurnstileVerify = (token) => {
    setTurnstileResponse(token);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return; // Prevent multiple submissions

    if (!turnstileResponse) {
      toast({
        title: "Error",
        description: "Please complete the Turnstile challenge before submitting.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await axiosInstance.post('/api/v1/accounts/sign-up/', {
        email,
        password,
        password2,
        referral_id: referralId,
        cf_turnstile_response: turnstileResponse
      });
      console.log(response.data);
      toast({
        title: t('signup.success'),
        description: t('signup.success_message'),
        status: "success",
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      navigate(`/signup-successful?email=${encodeURIComponent(email)}`);
    } catch (error) {
      console.error(error.response.data);
      
      if (error.response && error.response.data) {
        const errorData = error.response.data;
        
        if (errorData.referral_id && errorData.referral_id.includes('Invalid or expired referral code.')) {
          toast({
            title: t('signup.referralcode-invalid-title'),
            description: t('signup.referralcode-invalid-desc'),
            status: "warning",
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
        }

        else if (errorData.cf_turnstile_response && errorData.cf_turnstile_response.includes('Invalid Turnstile response')) {
          toast({
            title: t('signup.turnstile-invalid-title'),
            description: t('signup.turnstile-invalid-desc'),
            status: "warning",
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });

        } else if (typeof errorData === 'object') {
          Object.keys(errorData).forEach(key => {
            toast({
              title: `Error in ${key}`,
              description: errorData[key].join(', '),
              status: "error",
              duration: 5000,
              isClosable: true,
              position: 'top-right',
            });
          });
        } else {
          toast({
            title: "Error",
            description: "An unexpected error occurred. Please try again.",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
        }
      } else {
        toast({
          title: "Error",
          description: "An unexpected error occurred. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderContent = () => (
    <VStack spacing={4} align="stretch" width="100%" mt={isMobile ? 8 : 5}>
      <Text fontSize={isMobile ? "24px" : "30px"} fontWeight="bold" textAlign="center" color="#364954">{t('signup.title')}</Text>
      <Text fontSize="sm" color="gray.500" textAlign="center">
        {t('signup.alreadyhaveaccount')} <Link color="blue.500" href="/sign-in">{t('signup.signinbutton')}</Link>
      </Text>
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <VStack spacing={4} width="100%">
          <FormControl isRequired isDisabled={isSubmitting}>
            <FormLabel>{t('signup.emailfield')}</FormLabel>
            <Input 
              type="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t('signup.email_placeholder')}
              />
          </FormControl>
          <FormControl isRequired isDisabled={isSubmitting}>
            <FormLabel>{t('signup.passwordfield')}</FormLabel>
            <InputGroup size='md'>
              <Input
                pr='4.5rem'
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={t('signup.password_placeholder')}
              />
              <InputRightElement width='4.5rem'>
                <Button h='1.75rem' size='sm' onClick={() => setShowPassword(!showPassword)} isDisabled={isSubmitting}>
                  {showPassword ? t('signup.password_hide') : t('signup.password_show')}
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <FormControl isRequired isDisabled={isSubmitting}>
            <FormLabel>{t('signup.confirmpasswordfield')}</FormLabel>
            <Input
              type="password"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
              placeholder={t('signup.confirmpassword_placeholder')}
            />
          </FormControl>
          <FormControl isDisabled={isSubmitting}>
            <FormLabel>{t('signup.referalid')}<Text as="span" color="gray.400" fontSize="sm">({t('signup.optional')})</Text></FormLabel>
            <Input
              type="text"
              value={referralId}
              onChange={(e) => setReferralId(e.target.value)}
              placeholder={t('signup.referalid_placeholder')}
              // readOnly={!!referralCode}
            />
          </FormControl>
          <Turnstile
            siteKey="0x4AAAAAAAgeO6JOT4_Euowv"
            onSuccess={handleTurnstileVerify}
            onError={(error) => {
              console.error('Turnstile error:', error);
              setTurnstileResponse(null);
            }}
            onExpire={() => {
              console.log("Turnstile expired"); 
              setTurnstileResponse(null);
            }}
            options={{
              theme: 'light',
              language: 'auto',
              explicitRender: true,
            }}
          />
          <Button 
            width="full" 
            type="submit" 
            isLoading={isSubmitting}
            loadingText={t('signup.submitting')}
            isDisabled={isSubmitting || !turnstileResponse || !email || !password || !password2}
            color="#ffffff" 
            backgroundColor="#364954"
            borderRadius="16px"
            _hover={{
              color:"#ffffff",
              backgroundColor:"#82B2CA"
            }}
          >
            {isSubmitting ? <Spinner size="sm" /> : t('signup.createaccount_button')}
          </Button>
        </VStack>
      </form>
      <Text fontSize="xs" color="gray.500" textAlign="left">
        {t('signup.terms1')}{' '}
        <Link color="blue.500" href="/terms" target="_blank" rel="noopener noreferrer">{t('signup.termofservices')}</Link> {t('signup.terms2')}{' '}
        <Link color="blue.500" href="/privacy" target="_blank" rel="noopener noreferrer">{t('signup.privacypolicy')}</Link>, {t('signup.terms3')} {t('signup.helpemailtext')}
      </Text>
    </VStack>
  );

  const renderPlaceholder = () => (
    <VStack spacing={4} align="stretch" width="100%">
      <Skeleton height="36px" width="80%" alignSelf="center" />
      <Skeleton height="20px" width="60%" alignSelf="center" />
      <VStack spacing={4} width="100%">
        <Skeleton height="40px" width="100%" />
        <Skeleton height="40px" width="100%" />
        <Skeleton height="40px" width="100%" />
        <Skeleton height="40px" width="100%" />
        <Skeleton height="100px" width="100%" /> {/* For Turnstile */}
        <Skeleton height="40px" width="100%" />
      </VStack>
      <Skeleton height="40px" width="100%" />
    </VStack>
  );

  return (
    <Flex direction="column" minHeight="100vh" height="100%">
      <Flex direction="column" flex="1" overflow="hidden">
        <Box
          flex="1"
          height="100%"
          bg="#f5f7fa"
          overflow="auto"
          p={isMobile ? 4 : 5}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <VStack width={isMobile ? "100%" : "500px"}>
            <Box 
              width="100%"
              bg="#ffffff"
              borderRadius="16px"
              p={isMobile ? 6 : 8} 
              position="relative"
              boxShadow="lg"
            >
              <Box position="absolute" top={4} left={8}>
                <Button
                  variant="outline"
                  onClick={() => navigate('/')}
                  bg="#ffffff"
                  size={isMobile ? "sm" : "md"}
                >
                  <ArrowLeft 
                    color="#364954"
                    size={isMobile ? "16px" : "18px"}
                  />
                </Button>
              </Box>

              <Box position="absolute" top={4} right={8} >
                <Menu>
                  <MenuButton as={IconButton} icon={<GrLanguage />} colorScheme="gray" variant="outline" aria-label="Language Switcher" size={isMobile ? "sm" : "md"} />
                  <MenuList>
                    <MenuOptionGroup type='radio' defaultValue={i18n.language}>
                      <MenuItemOption value='zh' onClick={() => handleLanguageChange('zh')}>中文</MenuItemOption>
                      <MenuItemOption value='en' onClick={() => handleLanguageChange('en')}>English</MenuItemOption>
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              </Box>
              
              {showContent ? renderContent() : renderPlaceholder()}
            </Box>

            <Box align="center" mt={isMobile ? 8 : 10} > 
              <Image src="/myCareerHelp.png" alt={t('layout.site_logo')} mb={-1} mx="auto" width={isMobile ? "150px" : "200px"} />
              <Text fontSize="14px" color="#737D82">Empowering Careers, One Step at a Time.</Text>
            </Box>
          </VStack>
        </Box>
      </Flex>
    </Flex>
  );
};

export default SignUp;
