import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import {
  Box, Container, Button, 
  VStack, Text,
  useToast,
  Menu, MenuButton, IconButton, MenuList, MenuOptionGroup, MenuItemOption,
  Icon, Link, Skeleton
} from '@chakra-ui/react';
import { GrLanguage } from "react-icons/gr";
import { MailSearch } from 'lucide-react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const SignUpSuccessful = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email') || '';
  const [countdown, setCountdown] = useState(60);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [, setLanguage] = useState(i18next.language || 'zh');
  const { t, i18n, ready } = useTranslation();
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (ready) {
      setShowContent(true);
    }
  }, [ready]);

  const handleLanguageChange = (value) => {
    setLanguage(value);
    i18next.changeLanguage(value);
  };

  useEffect(() => {
    let timer;
    if (countdown > 0 && isResendDisabled) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else if (countdown === 0) {
      setIsResendDisabled(false);
    }
    return () => clearTimeout(timer);
  }, [countdown, isResendDisabled]);

  const handleResendEmail = async () => {
    if (isResendDisabled) return;

    try {
      await axiosInstance.post('/api/v1/accounts/resend-verification/', { email });
      toast({
        title: t('signupsuccess.emailresent_title'),
        description:  t('signupsuccess.emailresent_message'),
        status: "success",
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      setCountdown(60);
      setIsResendDisabled(true);
    } catch (error) {
      toast({
        title: t('signupsuccess.emailresenfail_title'),
        description: t('signupsuccess.emailresenfail_message'),
        status: "error",
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const renderContent = () => (
    <VStack mt="5" spacing={6} align="center">
      <Icon boxSize="60px" color="#364954" ><MailSearch /></Icon>
      <Text fontSize="30px" fontWeight="bold" color="#364954">{t('signupsuccess.title')}</Text>
      <Text textAlign="center" color="gray.600">
        {t('signupsuccess.content_line1')}<br />
        {t('signupsuccess.content_line2')}<br />
        <Text as="span" fontWeight="bold">{email}</Text><br />
        {t('signupsuccess.content_line3')}
      </Text>
      <Link
        color={isResendDisabled ? "gray.400" : "blue.500"}
        onClick={handleResendEmail}
        _hover={{ textDecoration: isResendDisabled ? "none" : "underline" }}
        cursor={isResendDisabled ? "not-allowed" : "pointer"}
      >
        {t('signupsuccess.resent_button')} {isResendDisabled ? `(${countdown}s)` : ''}
      </Link>
    </VStack>
  );

  const renderPlaceholder = () => (
    <VStack mt="5" spacing={6} align="center">
      <Skeleton height="60px" width="60px" />
      <Skeleton height="36px" width="80%" />
      <Skeleton height="80px" width="100%" />
      <Skeleton height="20px" width="50%" />
      <Skeleton height="40px" width="100%" />
    </VStack>
  );

  return (
    <Box 
      bg="#f5f7fa"
      minHeight="100vh" 
      py={8}>
      <Container maxW="500px">
        <Box 
          borderWidth={1} 
          borderRadius="16px" 
          p={8} 
          position="relative"
          bg="rgba(255, 255, 255, 0.7)"
          backdropFilter="blur(10px)"
          boxShadow="lg"
        >
          <Box position="absolute" top={4} right={8}>
            <Menu>
              <MenuButton as={IconButton} icon={<GrLanguage />} colorScheme="gray" variant="outline" aria-label="Language Switcher" />
              <MenuList>
                <MenuOptionGroup type='radio' defaultValue={i18n.language}>
                  <MenuItemOption value='zh' onClick={() => handleLanguageChange('zh')}>中文</MenuItemOption>
                  <MenuItemOption value='en' onClick={() => handleLanguageChange('en')}>English</MenuItemOption>
                </MenuOptionGroup>
              </MenuList>
            </Menu>
          </Box>
          {showContent ? renderContent() : renderPlaceholder()}
        </Box>
      </Container>
    </Box>
  );
};

export default SignUpSuccessful;