import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box, VStack, Heading, Text, Spacer, Button, IconButton, Divider,
  Input, HStack, useToast, Flex, Step, Stepper, useSteps,
  StepIndicator, StepStatus, StepIcon, StepNumber, StepTitle, StepSeparator,
  Popover, 
  PopoverTrigger, 
  PopoverContent, 
  PopoverBody, 
  PopoverFooter, 
  PopoverArrow,
  Skeleton, SkeletonText, Grid, GridItem, Fade, Spinner
} from '@chakra-ui/react';
import axiosInstance from '../../utils/axiosConfig';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Delete } from 'lucide-react';
import { UserContext } from '../UserContext';
import { useTranslation } from 'react-i18next';


  const TicketDetails = () => {
    const { id } = useParams();
    const [ticket, setTicket] = useState(null);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const toast = useToast();
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const [openPopoverId, setOpenPopoverId] = useState(null);
    const { t } = useTranslation();  
    const [pageLoading, setPageLoading] = useState(true);
    const [addCommentLoading, setAddCommentLoading] = useState(false);
    const [deleteCommentLoading, setDeleteCommentLoading] = useState(null);
    const maxLength = 499;

    const steps = [
        { title: t('feedback.ticketdetail.step-open') },
        { title: t('feedback.ticketdetail.step-inprocess') },
        { title: t('feedback.ticketdetail.step-close') },
      ];

    const { activeStep, setActiveStep } = useSteps({
      index: 0,
      count: steps.length,
    });
  
    useEffect(() => {
      const fetchTicketAndComments = async () => {
        setPageLoading(true);
        try {
          const ticketResponse = await axiosInstance.get(`/api/v1/feedback/tickets/${id}/`);
          setTicket(ticketResponse.data);
          
        // Set the active step based on the ticket status
        const statusToStepIndex = {
            'OPEN': 0,
            'IN_PROCESS': 1,
            'CLOSED': 2
        };
        setActiveStep(statusToStepIndex[ticketResponse.data.status] || 0);
  
          const commentsResponse = await axiosInstance.get(`/api/v1/feedback/tickets/${id}/comments/`);
          setComments(commentsResponse.data);
        } catch (error) {
          console.error('Error fetching ticket or comments:', error);
          toast({
            title: "Error fetching data",
            description: "Unable to load ticket details or comments.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
        setPageLoading(false);
      };
  
      fetchTicketAndComments();
    }, [id, toast]);

    const handleNewCommentChange = (e) => {
        const input = e.target.value;
        if (input.length <= maxLength) {
          setNewComment(input);
        }
      };

  const handleAddComment = async () => {
    if (!newComment.trim() || newComment.length > maxLength) return;
    setAddCommentLoading(true);

    try {
      const response = await axiosInstance.post(`/api/v1/feedback/tickets/${id}/comments/`, {
        content: newComment
      });
      setComments([...comments, response.data]);
      setNewComment('');
      toast({
        title: t('feedback.ticketdetail.toast-comments-added'),
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      console.error('Error adding comment:', error);
      toast({
        title: t('feedback.ticketdetail.toast-comments-adderror'),
        description: "Unable to add your comment. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
        setAddCommentLoading(false);
    }
    };

  const handleDeleteComment = async (commentId) => {
    setDeleteCommentLoading(commentId);
    try {
      await axiosInstance.delete(`/api/v1/feedback/tickets/${id}/comments/${commentId}/`);
      setComments(comments.filter(comment => comment.id !== commentId));
      toast({
        title: t('feedback.ticketdetail.toast-comments-deleted'),
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      console.error('Error deleting comment:', error);
      toast({
        title: t('feedback.ticketdetail.toast-comments-deleteerror'),
        description: "Unable to delete the comment. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
        setDeleteCommentLoading(null);
    }
    };

  const renderCommentUser = (comment) => {
    if (comment.is_admin) {
      return 'Admin';
    }
    return comment.user === user.display_name ? 'You' : comment.user;
  };

  const LoadingSkeleton = () => (
    <>
      <HStack>
        <Skeleton height="100px" width="50%" mb={4} borderRadius={16}/>
        <Skeleton height="100px" width="50%" mb={4} borderRadius={16}/>
      </HStack>
      <Box 
        borderRadius={16}
        mb={3}
        background="rgba(255, 255, 255, 0.6)"
        transition="all 0.3s ease"
      >
        <Box p={5}>
          <Grid templateColumns="repeat(2, 1fr)" gap={8}>
            {[...Array(6)].map((_, index) => (
              <GridItem key={index}>
                <SkeletonText noOfLines={2} spacing="4" />
              </GridItem>
            ))}
          </Grid>
        </Box>
      </Box>
      <Skeleton height="200px" width="100%" mb={4} borderRadius={16}/>
    </>
  );


  return (
    <Flex direction="column" minHeight="100%" height="100%">
      <Box height="30px" flexShrink={0} />
      <Flex direction="column" flex="1" overflow="hidden">
        <Flex align="center" mb={4}>
          <IconButton
            icon={<ArrowBackIcon />}
            onClick={() => navigate('/feedback')}
            mr={4}
            aria-label="Go back"
          />
          <Heading fontSize="2xl">{t('feedback.ticketdetail.title')}</Heading>
        </Flex>
  
        <Box
          flex="1"
          borderTopLeftRadius="16px"
          borderTopRightRadius="16px"
          overflow="hidden"
          bg="#f5f7fa"
        >
            <Box 
                height="100%"
                overflow="auto"
                p={5}
            >
            {pageLoading ? (
            <LoadingSkeleton />
            ) : (
            <Fade in={!pageLoading}>
                <Flex direction="row" justifyContent="space-between" mb="4">
                <Box 
                    width="50%" 
                    p="4"
                    bg="#FFFFFF"
                    borderRadius={16}
                >
                    <Text mb="3" fontSize="16px" fontWeight="bold" textColor="#364954" >
                    {t('feedback.ticketdetail.info-title')}:
                    </Text>
                    <Box fontSize="14px" textColor="#737D82">
                        <Flex justifyContent="space-between"><Text as="b">{t('feedback.ticketdetail.info-id')}:</Text><Text>{ticket.id}</Text></Flex>
                        <Flex justifyContent="space-between"><Text as="b">{t('feedback.ticketdetail.info-cat')}:</Text><Text>{ticket.category}</Text></Flex>
                        <Flex justifyContent="space-between"><Text as="b">{t('feedback.ticketdetail.info-createat')}:</Text><Text>{new Date(ticket.created_at).toLocaleString()}</Text></Flex>
                    </Box>
                </Box>

                <Box 
                    width="50%" 
                    p="4"
                    bg="#FFFFFF"
                    borderRadius={16}
                    ml="4"
                    align="left"
                >
                    <Text mb="3" fontSize="16px" fontWeight="bold" textColor="#364954">
                    {t('feedback.ticketdetail.info-status')}: {steps[activeStep].title}
                    </Text>

                    <Stepper 
                        index={activeStep} 
                        colorScheme= {activeStep === 0 ? "yellow" : activeStep === 1 ? "blue" : "green" } 
                        width="100%" mt="10"
                    >
                        {steps.map((step, index) => (
                        <Step key={index}>
                            <StepIndicator>
                            <StepStatus
                                complete={<StepIcon />}
                                incomplete={<StepNumber />}
                                active={<StepNumber />}
                            />
                            </StepIndicator>

                            <Box flexShrink='0'>
                            <StepTitle>{step.title}</StepTitle>
                            </Box>

                            <StepSeparator />
                        </Step>
                        ))}
                    </Stepper>
                </Box>

                </Flex>
                        
                <Box
                    flex="1"
                    borderRadius="16px"
                    bg="#FFFFFF"
                    p={3}
                    minH="200px"
                    overflow="auto"
                    mb="8"
                >
                    <Text mb="3" fontSize="16px" fontWeight="bold" textColor="#364954">
                    {t('feedback.ticketdetail.des-title')}:
                    </Text>
                    <Text whiteSpace="pre-wrap" fontSize="14px">{ticket.description}</Text>
                </Box>
  

              <Heading fontSize="16px" textColor="#364954" mb="4">{t('feedback.ticketdetail.comments-title')}</Heading>

              <VStack align="stretch" width="100%" spacing={4} mb="4">
                    <HStack width="100%">
                        <Flex
                            bg="#FFFFFF"
                            borderRadius={12}
                            alignItems="center"
                            flex={1}
                        >
                        <Input
                            borderRadius={12}
                            value={newComment}
                            onChange={handleNewCommentChange}
                            isDisabled={activeStep === 2 || addCommentLoading}
                            placeholder= { activeStep === 2 ? t('feedback.ticketdetail.cmt-placeholder-disabled') : t('feedback.ticketdetail.cmt-placeholder-normal')}
                            maxLength={maxLength}
                            borderWidth={0}
                        />
                        <Text mx="2" fontSize="12px" color={newComment.length === maxLength ? "red.500" : "gray.500"}>
                            {newComment.length}/{maxLength}
                        </Text>
                        </Flex>
                        <Button 
                            onClick={handleAddComment}
                            borderRadius={12}
                            isDisabled={activeStep === 2 || addCommentLoading || newComment.length === 0}
                            isLoading={addCommentLoading}
                            loadingText={t('feedback.ticketdetail.btn-addcomment-loading')}
                        >
                            {t('feedback.ticketdetail.btn-addcomment')}
                        </Button>
                    </HStack>

                <Box
                  borderRadius={12}
                  bg="white" 

                >
                {comments
                .slice()
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                .map((comment) => (
                    <Box key={comment.id} 
                        overflow="hidden" 
                        px="5"
                        pt="4"
                    >
                    <Box mb="1">
                        <Flex justifyContent="space-between" alignItems="center" mb={2}>
                        <Text fontWeight="bold" fontSize="sm">
                            {renderCommentUser(comment)}
                        </Text>
                        <Spacer />
                        <HStack>
                        <Text fontSize="xs" color="gray.500">
                            {new Date(comment.created_at).toLocaleString()}
                        </Text>
                        { comment.user === user.display_name && 
                        <Popover 
                            isOpen={openPopoverId === comment.id}
                            onClose={() => setOpenPopoverId(null)}
                        >
                            <PopoverTrigger>
                            {deleteCommentLoading === comment.id ? (
                              <Spinner size="sm" />
                            ) : (
                                <Delete
                                size="14px"
                                cursor="pointer"
                                onClick={() => setOpenPopoverId(comment.id)}
                            />
                            )}
                            </PopoverTrigger>
                            <PopoverContent
                                borderRadius="16px"
                                p={1}
                            >
                                <PopoverArrow />
                                <PopoverBody>
                                    <Flex>
                                    <Text>{t('feedback.ticketdetail.cmt-delete-des')}</Text>
                                </Flex>
                                </PopoverBody>
                                <PopoverFooter display="flex" justifyContent="flex-end">
                                <Button 
                                    borderRadius={12}
                                    size="sm" 
                                    onClick={() => handleDeleteComment(comment.id)} 
                                    colorScheme="red"
                                    isDisabled={deleteCommentLoading === comment.id}
                                    isLoading={deleteCommentLoading === comment.id}
                                    loadingText={t('feedback.ticketdetail.cmt-delete-btn-loading')}
                                >
                                    {t('feedback.ticketdetail.cmt-delete-btn-confirm')}
                                </Button>
                                <Button 
                                    borderRadius={12}
                                    size="sm"
                                    ml="2"
                                    onClick={() => setOpenPopoverId(null)}
                                    isDisabled={deleteCommentLoading === comment.id}
                                >
                                    {t('feedback.ticketdetail.cmt-delete-btn-cancel')}
                                </Button>
                                </PopoverFooter>
                            </PopoverContent>
                            </Popover>
                            }
                        </HStack>
                        </Flex>
                        <Text fontSize="14px" whiteSpace="pre-wrap">
                        {comment.content}
                        </Text>
                        <Divider mt="3" />
                        </Box>
                        </Box>
                    ))}
                </Box>
              </VStack>
              </Fade>
            )}
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
};

export default TicketDetails;