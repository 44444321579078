// src/components/HomePage/OurSolutions.js
import React, { useState, useEffect } from 'react';
import { Box, Heading, SimpleGrid, Text, VStack, HStack, Image, Badge, Icon, 
  Skeleton, SkeletonText, useBreakpointValue 
} from '@chakra-ui/react';
import { WandSparkles, Blocks, NotebookPen, MonitorPlay } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const OurSolutionsOtherFeatures = () => {
  const { t, i18n, ready } = useTranslation("homepage");
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (ready) {
      setShowContent(true);
    }
  }, [ready]);

  const services = [
    {
      title: t('resumeBuilder.title'),
      intro: t('resumeBuilder.intro'),
      icon: <Blocks />,
      status: "building",
    },
    {
      title: t('interviewPrep.title'),
      intro: t('interviewPrep.intro'),
      icon: <NotebookPen />,
      status: "building",
    },
    {
      title: t('mockInterview.title'),
      intro: t('mockInterview.intro'),
      icon: <MonitorPlay />,
      status: "building",
    }
  ];


  const OtherServiceCard = ({ service }) => (
    <Box
      bg="white"
      borderRadius="15px"
      p={10}
      textAlign="center"
    >
      <VStack >
        <Icon fontSize="40px" color="#737D82"> {service.icon} </Icon>
        <Text fontWeight="bold" color="#737D82">{service.title}</Text>
        {service.status === "building" && 
        <Badge 
        //   backgroundColor="#CFD9DF"
          bg="linear-gradient(to right, #9795f0 0%, #fbc8d4 100%);" 
          textColor="#FFFFFF" 
          borderRadius="full"
          fontSize="12px"
          mb="4"
        >
          {t('comingSoon')}
        </Badge>}
        <Text fontSize="14px" color="#737D82">{service.intro}</Text>
      </VStack>
    </Box>
  );


  const OtherServiceCardPlaceholder = () => (
    <Box
      bg="gray.100"
      borderRadius="15px"
      p={10}
      textAlign="center"
    >
      <VStack>
        <Skeleton height="40px" width="40px" />
        <Skeleton height="24px" width="80%" />
        <Skeleton height="20px" width="60%" />
        <SkeletonText mt="4" noOfLines={2} spacing="4" />
      </VStack>
    </Box>
  );

  const otherServices = services.filter(s => s.status === "building");


  return (
    <Box 
      py={20}
      px={6}
    //   bg="#f5f7fa"
      bg="linear-gradient(to bottom, #f5f7fa 0%, #e7f0fd 100%)"
    >
      <Heading as="h2" size="xl" textAlign="center" mb={10}>
        {showContent ? t('v2.otherfeatures') : <Skeleton height="40px" width="200px" mx="auto" />}
      </Heading>
      <Box maxW="1200px" mx="auto">
        <Text 
          textAlign="center" 
          fontSize="20px"
          color="#364954"
          mt="10" 
          mb="8"
        >
          {showContent ? t('continuousDevelopment') : <Skeleton height="24px" width="80%" mx="auto" />}
        </Text>
        <SimpleGrid columns={[1, null, 3]} spacing={6}>
          {showContent ? (
            otherServices.map((service, index) => (
              <OtherServiceCard key={index} service={service} />
            ))
          ) : (
            [1, 2, 3].map((_, index) => (
              <OtherServiceCardPlaceholder key={index} />
            ))
          )}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default OurSolutionsOtherFeatures;