// src/components/HomePage/HomePage.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import HomePageLayout from './HomePageLayout';
import OurCommitment from './OurCommitment';
import FAQ from './FAQ';
import CallToAction from './CallToAction';
import OurSolutionsResumePolish from './OurSolutions-ResumePolish';
import OurSolutionsCreateWithAI from './OurSolutions-CreateWithAI';
import OurSolutionsOtherFeatures from './OurSolutions-OtherFeatures';
import HeroNew from './HeroNew';

const HomePage = () => {
  const { t } = useTranslation('homepage');
  
  return (
    <HomePageLayout>
      <HeroNew />
      <OurSolutionsResumePolish />
      <OurSolutionsOtherFeatures />
      <OurSolutionsCreateWithAI />
      <OurCommitment />
      <FAQ />
      <CallToAction />
    </HomePageLayout>
  );
};

export default HomePage;